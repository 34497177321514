.fixedPolicy {
    position: fixed;
    z-index: 10;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(
        180deg,
        rgba(243, 243, 248, 0) 0%,
        rgba(243, 243, 248, 0.11) 6.25%,
        rgba(243, 243, 248, 0.21) 11.98%,
        #f3f3f8 38.54%
    );
    height: 52px;
    padding-bottom: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.colorMain {
    color: var(--on-surface-text-main) !important;
}
