.wrapper {
    width: 100%;
}

.logoAndNamePart {
    display: flex;
    gap: 16px;
}

.logo {
    min-width: 90px;
}

.ratingWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rating {
    display: flex;
    align-items: center;
    gap: 8px;
}

.starsWrapper {
    display: flex;
    align-items: center;
    gap: 2px;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.phoneBlock {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
}
