.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: calc(50% - 6px);
    position: relative;
    margin-bottom: 16px;
    background: none;
    overflow: hidden;
}

.card:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 50%;
    width: 164px;
    height: 164px;
    margin-left: -82px;
    background: var(--surface-default);
    border: 1px solid var(--on-surface-border-subdued);
    border-radius: 100%;
    box-sizing: border-box;
}

.buttonBox {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: -44px;
    border-radius: 100px;
    padding: 14px 16px;
    background-color: var(--primary-default);
}

.imageWrapper {
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.nextIcon {
    margin-left: 9px;
    margin-top: -2px;
}

@media screen and (min-width: 768px) {
    .card {
        width: 165px;
        height: 180px;
        margin-right: 16px;
    }

    .card:nth-child(-n + 2) {
        margin-bottom: 24px;
    }
}
