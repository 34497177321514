.legend {
    display: flex;
    gap: 12px;
}

.markWrapper {
    width: 105px;
    height: 105px;
}

.starsBlock {
    display: flex;
    gap: 6px;
    flex-direction: column-reverse;
    justify-content: end;
}

.starsRow {
    display: flex;
    gap: 8px;
    align-items: center;
}

.starsWrapper {
    display: flex;
    align-items: center;
    gap: 2px;
    flex-direction: row-reverse;
}

.progressBar {
    width: 160px;
    height: 2px;
    background-color: var(--surface-secondary);
    border-radius: 5px;
    position: relative;
}

.progressBar:after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    background-color: var(--on-surface-border-default);
}

.starsRow:nth-of-type(5) .progressBar:after {
    width: 140px;
}

.starsRow:nth-of-type(4) .progressBar:after {
    width: 16px;
}

.starsRow:nth-of-type(3) .progressBar:after {
    width: 6px;
}

.starsRow:nth-of-type(2) .progressBar:after {
    width: 2px;
}

.starsRow:nth-of-type(1) .progressBar:after {
    width: 5px;
}

.ratings {
    text-align: right;
}
