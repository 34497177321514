.listItem {
    display: flex;
    justify-content: space-between;
}

.listItem > *:first-child {
    min-width: 80px;
    max-width: 80px;
}

.listItem > *:last-child {
    text-align: right;
}
