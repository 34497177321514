.sliderInner {
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + var(--default-indent-double));
    margin-bottom: 80px;
}

.image {
    width: auto;
    height: 100%;
    margin: 0 auto;
}

.dot {
    display: block;
    height: 10px;
    width: 10px;
    background-color: var(--surface-secondary);
    border-radius: 50%;
}
