.listItem {
    position: relative;
    display: flex;
}

.listItem:before {
    content: '•';
    margin-right: 0.5ch;
    display: block;
    position: relative;
}
